<template>
  <div class="row">
    <div class="col-md-6">
      <Print :items="items" :data="data" :setting="setting" />
    </div>
    <div class="col-md-3">
      <PrintThermal :items="items" :data="data" :setting="setting" />
    </div>

    <!-- PDF -->
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
      :paginate-elements-by-height="1400" :filename="
        'struk-apotek-' + data.patient_name == ''
          ? data.code
          : data.patient_name
      " :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait"
      pdf-content-width="800px" @progress="onProgress($event)" @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)" ref="html2Pdf">
      <section slot="pdf-content">
        <Print :items="items" :data="data" :setting="setting" />
        <!-- <PrintThermal :items="items" :data="data" :setting="setting" /> -->
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button squared class="mt-2" size="sm" variant="primary" @click="btnPrintOnClick">Print</b-button>
      <b-button squared :class="'d-block mt-2'" size="sm" variant="warning" @click="btnPrintThermalOnClick">Print
        Thermal</b-button>
      <b-button squared :class="'d-block mt-2'" size="sm" variant="info" @click="btnDownloadOnClick">Download</b-button>
      <!-- <b-button
          squared
          :class="'d-block mt-2'"
          size="sm"
          variant="success"
          @click="$router.push('/pharmacy/cashier/' + data.id)"
        >Edit Order</b-button> -->
      <b-button squared :class="'d-block mt-2'" size="sm" variant="success"
        @click="$router.push('/pharmacy/cashier')">Tambah Baru</b-button>
    </div>
    <PrintThermalReal :items="items" :data="data" :setting="setting" class="d-none" />
  </div>
</template>

<script>

// components
import Print from "@/component/pharmacy/pharmacy-orders/Print.vue";
import PrintThermal from "@/component/pharmacy/pharmacy-orders/PrintThermal.vue";
import PrintThermalReal from "@/component/pharmacy/pharmacy-orders/PrintThermalReal.vue";

import module from "@/core/modules/CrudModule.js";
import VueHtml2pdf from "vue-html2pdf";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    VueHtml2pdf,
    Print,
    PrintThermal,
    PrintThermalReal,
  },

  data() {
    return {
      // Data
      data: {},
      // Table Items
      items: [],
      setting: {},
    };
  },

  methods: {
    async get() {
      this.data = await module.get(
        `pharmacy-payments/get-by-order/${this.$route.params.id}`
      );
      this.items = this.data.order_details;
    },

    async getSetting() {
      this.setting = await module.get("website-settings/get-default");
      if (this.setting == null) {
        this.setting = {
          address:
            "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          phone: "(021) 3909333",
        };
      }
      console.log("setting", this.setting);
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-pharmacy");
    },

    btnPrintThermalOnClick() {
      this.$htmlToPaper("print-pharmacy-thermal");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },
  },

  mounted() {
    let conf = JSON.parse(localStorage.getItem("user"));
    this.setting = {
      address: conf.address,
      phone: conf.phone,
    };
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "" },
      { title: "Penjualan", route: "/pharmacy/sales" },
      { title: "Detail Penjualan" },
    ]);
    // Get
    this.get();
    // this.getSetting();
  },
};
</script>

<style></style>
