<template>
  <div class="card" style="border: none">
    <div class="card-body">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12 px-1 text-center">
          <img :src="setting.logo" height="60" />
          <div class="font-weight-light font-default">
            {{ setting.address ? setting.address : "" }}
          </div>
          <div class="font-weight-light font-default">
            {{ setting.phone ? setting.phone : "" }}
          </div>
        </div>
        <div class="col-md-12 px-1 font-default">
          <table style="font-size: 10pt !important;">
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px">:</td>
              <td>{{ data.code }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px">:</td>
              <td>{{ data.display_transaction_date }}</td>
            </tr>
            <tr v-if="data.patient_name != ''">
              <td>Nama Pasien</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_name }}</td>
            </tr>
            <tr v-if="data.doctor_name != ''">
              <td>Dokter</td>
              <td style="width: 9px">:</td>
              <td>{{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- <div
        class="row justify-content-center"
        style="font-size: 12px;"
      >
        <div class="col-md-6">
          <table class="font-weight-bold">
            <tr v-if="data.patient_name != ''">
              <td style="width: 120px; padding: 5px">Nama Pasien</td>
              <td>: {{ data.patient_name }}</td>
            </tr>
            <tr v-if="data.doctor_name != ''">
              <td style="width: 120px; padding: 5px">Dokter</td>
              <td>: {{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6">
          <table class="font-weight-bold">
            <tr v-if="data.patient_name != ''">
              <td style="width: 120px; padding: 5px">Nama Pasien</td>
              <td>: {{ data.patient_name }}</td>
            </tr>
          </table>
        </div>
      </div> -->
      <!-- <b-table
        :items="items"
        :fields="fields"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
          />
        </template>
        <template #cell(amount)="data">
          {{ 'Rp ' + parseInt(data.item.amount).toLocaleString('id-ID') }}
        </template>
        <template #cell(total)="data">
          {{ 'Rp ' + parseInt(data.item.amount * data.item.quantity).toLocaleString('id-ID') }}
        </template>
      </b-table> -->

      <div style="font-size: 10pt">
        <div class="row">
          <!-- <span>===========================</span> -->
          <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
          <!-- item -->
          <div class="col-md-12 px-1 py-1" v-for="(data, index) in items" :key="index">
            <span class="d-block">{{ data.medicine_name }}</span>
            <div class="row px-3 py-1 justify-content-between">
              <span class="pl-3">{{ data.quantity }} x
                {{ (parseInt(data.amount) - parseInt(data.promo_amount)).toLocaleString("id-ID") }}</span>
              <span>
                {{
                  ((parseInt(data.amount) - parseInt(data.promo_amount)) * data.quantity).toLocaleString("id-ID")
                }}
              </span>
            </div>
          </div>
          <!-- <span>===========================</span> -->
          <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
        </div>
      </div>

      <div class="row py-1" style="font-size: 10pt">
        <div class="col-md-12 p-0">
          <span class="font-weight-bold d-block">Catatan :</span>
        </div>
        <div class="col-md-12 px-0 pt-1">
          <span>{{ data.notes }}</span>
        </div>
        <!-- <span>===========================</span> -->
        <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
      </div>
      <div class="mt-3 row justify-content-center align-items-center">
        <table style="font-size: 10pt !important;">
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td class="font-weight-bold">Rp</td>
            <td class="text-right font-weight-bold">
              {{ parseInt(data.total_amount - data.total_promo_amount).toLocaleString('id-ID') }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">Biaya Tambahan</td>
            <td class="font-weight-bold">Rp</td>
            <td class="text-right font-weight-bold">
              {{ parseInt(data.surcharge).toLocaleString("id-ID") }}
            </td>
          </tr>
          <tr v-if="data.coupon_amount">
            <td>Kupon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">
                {{ parseInt(data.coupon_amount).toLocaleString('id-ID') }}
              </span>
            </td>
          </tr>
          <tr>
            <td style="width: 150px">Total Keseluruhan</td>
            <td class="font-weight-bold">Rp</td>
            <td class="text-right font-weight-bold">
              {{ parseInt(data.final_amount).toLocaleString("id-ID") }}
            </td>
          </tr>
          <template v-if="data.pharmacy_payment_method_id == 1">
            <tr>
              <td>
                <hr style="margin: 5px" />
              </td>
              <td>
                <hr style="margin: 5px" />
              </td>
              <td>
                <hr style="margin: 5px" />
              </td>
            </tr>
            <tr>
              <td style="width: 150px">Uang</td>
              <td class="font-weight-bold">Rp</td>
              <td class="text-right font-weight-bold">
                {{ parseInt(data.payment_amount).toLocaleString("id-ID") }}
              </td>
            </tr>
            <tr>
              <td style="width: 150px">Kembalian</td>
              <td class="font-weight-bold">Rp</td>
              <td class="text-right font-weight-bold">
                {{ parseInt(data.change_amount).toLocaleString("id-ID") }}
              </td>
            </tr>
          </template>
          <tr>
            <td style="width: 150px">Pembayaran</td>
            <td style="width: 25px"></td>
            <td class="text-right font-weight-bold">
              {{ data.payment_method_name }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    items: Array,
    // setting: Object
  },

  data() {
    return {
      fields: [
        {
          key: "medicine_name",
          label: "Obat",
        },
        {
          key: "quantity",
          label: "Jumlah",
        },
        {
          key: "amount",
          label: "Harga Satuan",
        },
        {
          key: "quantity",
          label: "Jumlah",
        },
        {
          key: "total",
          label: "Total",
        },
      ],
      setting: {
        logo: window.configuration.logo,
        address: window.configuration.pharmacy_address,
        phone: window.configuration.pharmacy_phone,
      },
    };
  },
};
</script>

<style scoped>
.font-default {
  font-size: 10pt !important;
}
</style>